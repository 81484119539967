import React from 'react';
import Header from './header';
import midimage from './1.jpg';
import {motion} from 'framer-motion';
import NumberCounter from 'number-counter';
import './home.css';

function Home(){
    const transition = {type:'spring',duration : 3}
    return(
    <div className='index'>
      <div className='left-h'>
        <Header/>
        <div>
          <div className='the-ad'>
            <motion.div 
            initial={{left: '238px'}}
            whileInView={{left:'8px'}}
            transition={{...transition,type: 'tween'}}
            >
            </motion.div>
            <span>Crypto and Stocks in one place</span>
          </div>
          <br/>
          <div className='mid-text'>
            <div className='stroke-text'>
              <span>Price </span>
              <span>Tracker</span>
            </div>
            <div className='stroke-text'>
              <span>Converter</span>
            </div>
            <div className='stroke-text'>
              <span>Portfolio</span>
            </div>
          </div>
          <br/>
          <div className='figures'>
            <div>
              <span>
                <NumberCounter end={99} start={50} delay='4' preFix="+"/>
              </span>
              <span>Cryptos</span>
            </div>
            <div>
              <span>
              <NumberCounter end={99} start={50} delay='4' preFix="+"/>
              </span>
              <span>Stocks</span>
            </div>
            <div>
              <span>
              <NumberCounter end={5} start={0} delay='2.5' preFix="+"/>
              </span>
              <span>Currencies</span>
            </div>
          </div>
          <br/><br/>
          <div className='mid-buttons'>
            <button className='btn'>Sign Up</button>
            <button className='btn'>Sign In</button>
          </div>
        </div>
      </div>
      <div className='right-h'>
          <button className='btn'>Sign Up</button>
          <button className='btn'>Sign In</button>
          <br/><br/>
          <img src={midimage} alt="Not Found" className='mid-img'/>
          <br/><br/><br/>
      </div>
    </div>
    );
}
export default Home;