import React from 'react';
import './description.css'
import {programsData} from './data';
function Description()
{ 
    return(
        <div className='Description' id='description'>
            <br/><br/>
            <div className='Description-header'>
                <span className='stroke-text'>Know more about Stocks and Crypto</span>
            </div>
            <div className='data-categories'>
                {programsData.map((program) => 
                    <div className='category'>
                        <span>{program.heading}</span><span>{program.details}</span>
                    </div>
                )}
            </div>
        </div>
    );
}
export default Description;    