import React from 'react';
import ReactDOM from 'react-dom';
import Description from './description';
import Footer from './footer';
import Home from './home';
import bgimg from './2.jpg';
import './index.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './header';
import Price from './price'

ReactDOM.render(
  <>
    <img src={bgimg} alt="background" className='bg-img'/>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={
        <>
          <Home/>
          <Description/>
          <Footer/>
        </>}/>
        <Route path='price' element={<Price/>}/>
        <Route path='portfolio' element={<div>Portfolio</div>}/>
        <Route path='converter' element={<div>Converter</div>}/>
        <Route path='about' element={<div>About us</div>}/>
      </Routes>
    </BrowserRouter>
    
    
  </>
  ,document.getElementById("root"));
