import React from 'react';
import './header.css'
import Bars from './bars.png';
import {useState} from 'react';
import {NavLink} from 'react-router-dom';
import Price from './price'
function Header() {
    const mobile = window.innerWidth <= 768 ? true : false;
    const [menuOpened, setMenuOpened] = useState(false);
    return (
        <div className='header'>
            {/* <img className='logo' src={Logo} alt="logo"/> */}
            { menuOpened=== false && mobile === true? (
            <div style={{
                backgroundColor: "transparent",
                padding: "0.5rem",
                borderRadius: "5px",
                }}
                onClick={() => setMenuOpened(true)}
            >
                <img src={Bars} alt="bars"
                style={{width:'1.5rem',height:'1.5rem'}} />
            </div>):
            (<ul className='header-menu'>
                <li onClick={()=> setMenuOpened(false)}>
                <NavLink to='/'>Home</NavLink> </li>
                <li onClick={()=> setMenuOpened(false)}>
                <NavLink to='/price'>Price</NavLink> </li>
                <li onClick={()=> setMenuOpened(false)}>
                <NavLink to='/converter'>Converter</NavLink> </li>
                <li onClick={()=> setMenuOpened(false)}>
                <NavLink to='/portfolio'>Portfolio</NavLink> </li>
                <li onClick={()=> setMenuOpened(false)}>
                <NavLink to='/about'>About us</NavLink> </li>
            </ul>)}

        </div>
    );
}
export default Header;            