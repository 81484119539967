import React from 'react';
import './footer.css';
import github from './github.png';
import linkedin from './linkedin.png';
import gmail from './gmail.jpg';
function Footer()
{ 
    return(
        <div className='footer-container'>
            <br/><br/>
            <hr/>
            <div className='footer'>
                <div className='social'>
                    <img src={github} alt='github' />
                    <img src={linkedin} alt='linkedin'/>
                    <img src={gmail} alt='linkedin'/>
                </div>
                <div className='info'>
                    <span>©Navneet Khar</span>
                </div>
                {/* <div className='logo-footer'>
                    <img src={logo} alt='logo'/>
                </div> */}
            </div>
        </div>
    );
}
export default Footer;    