import React from 'react';
import ReactDOM from 'react-dom';
import Description from './description';
import Footer from './footer';
import Home from './home';
import bgimg from './2.jpg';
import Header from './header';
import './price.css';
import Pricecall from './pricecall'

function Price(){
    return(
        <div>
        <div className='index'>
            <div className='left-h'>
            <Header/>
            </div>
            <div className='right-h'>
                <button className='btn'>Sign Up</button>
                <button className='btn'>Sign In</button>
            </div>
            
        </div>
        <Pricecall/>
        </div>
      );
}
export default Price;
