export const programsData = [
    {
      heading: "Cryptocurrency",
      details:
        "Currency that exists digitally or virtually and uses cryptography to secure transactions.",
    },
    {
      heading: "Top Cryptocurrency Currently",
      details:
        "Bitcoin , Ethereum , Tether , BNB , Cardano , Solana and many more",
    },
    {
      heading: "Stock",
      details:
        "Stocks represent ownership in a publicly traded company.",
    },
    {
      heading: "Top Stocks Currently",
      details:
        "Apple , Saudi Arabian Oil , Microsoft , Alphabet ,Amazon and many more  ",
    },
  ];